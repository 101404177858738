import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="hero">
        <div className="hero-content-wrapper">
            <div className="hero-text-wrapper">
                <div className="hero-text">
                    <h1>Badass Corporation</h1>
                    <h3>New Website Coming Soon</h3>
                </div>
            </div>
        </div>
    </div>
  </Layout>
)

export default IndexPage
